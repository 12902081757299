export default function Privacy() {
    return (<>
            <p>Rewards Fuel LLC (“we” or “us”) is committed to protecting the privacy of our users (“you”). This Privacy
                Policy explains what information we collect, how we use it, and the choices you have with respect to our
                use
                of your information. This policy applies to the [Company Name] software as a service (“SAAS”) platform,
                which is available through our website located at https://rewardsfuel.com.</p>

            <p>Information We Collect</p>

            <p>We collect information about you when you use our SAAS platform, including:</p>

            <p>Personal information: This includes information such as your name, email address, postal address, phone
                number, and any other information that can be used to identify you.
                Data you upload or provide to us: This includes any data, files, or content that you upload, store, or
                transmit using our SAAS platform.
                Log data: When you use our SAAS platform, we automatically collect log data, which includes information
                such
                as your IP address, browser type, operating system, and access times.
                Usage data: We collect information about how you interact with our SAAS platform, including the features
                you
                use, the pages you visit, and the time you spend on our platform.
                How We Use Your Information</p>

            <p>We use your information to provide, maintain, improve, and enhance our SAAS platform and services, and
                for
                the following purposes:</p>

            <p>To provide you with the services you requested: We use your information to provide you with the services
                you
                requested, such as to process transactions, send you information, or respond to your inquiries.
                To improve our services: We use your information to understand how you use our SAAS platform, to improve
                the
                quality and user experience of our services, and to develop new features and services.
                To communicate with you: We use your information to communicate with you, such as to send you updates,
                alerts, and other messages related to your use of our SAAS platform.
                For compliance with legal obligations: We may use your information as necessary to comply with our legal
                obligations, such as to respond to subpoenas, court orders, or legal process, or to enforce our
                agreements
                with you.</p>
            <p>Data Retention</p>

            <p>We will retain your information for as long as needed to provide you with our services, to comply with
                our
                legal obligations, and to enforce our agreements with you. We may also retain your information as needed
                to
                resolve disputes, to enforce our policies, and to prevent fraud.</p>

            <p>Your Choices</p>

            <p>You have the following choices with respect to our use of your information:</p>

            <p>Opt-out of marketing communications: You may opt-out of receiving promotional messages from us by
                following the opt-out instructions in the messages you receive.
                Access and control your information: You may access and control the information we have about you by
                contacting us at info@rewardsfuel.com.</p>
            <p>Data Security</p>

            <p>We have implemented appropriate technical and organizational measures to protect your information from
                unauthorized access, use, or disclosure. However, no security measures are 100% secure, and we cannot
                guarantee the security of your information.</p>

            <p>Changes to Our Privacy Policy</p>

            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or to comply with
                legal requirements. When we make changes to this policy, we will revise the “Last Updated” date at the
                top of this policy and post the updated policy on our website. We encourage you to review this Privacy
                Policy periodically to stay informed about our information practices.</p>

            <p>Contact Us</p>

            <p>If you have any questions about this Privacy Policy, please contact us at info@rewardsfuel.com.</p>
        </>
    );
}
