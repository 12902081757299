import userCircle from '../../userCircle.svg'
import Grid from "@mui/material/Grid";

export default function YoutubeWinner(props) {
    let winner = props.winner.commentor;
    const addDefaultSrc = (ev) => {
        ev.target.src = userCircle;
    }
    function fixEncoding(str) {
        return str.replace(/&#(\d+);/g, function(match, dec) {
            return String.fromCharCode(dec);
        });
    }
    let winnerUrl = "https://www.youtube.com/" + winner.customUrl;
    return (<>
        <div className={'winning-comment'}>
            <Grid container={true} spacing={2}>
                <Grid item={true} sm={2} xs={3}>
                    <div className={'comment-image'}><img onError={addDefaultSrc} src={winner.thumbnails.default.url}
                                                          alt={winner.title}/></div>
                </Grid>
                <Grid item={true} xs={9} sm={10}>
                    <div className={'comment-name'}><a href={winnerUrl}
                                                       target={"_blank"}>{winner.title} {winner.customUrl}</a></div>
                    <div className={'comment'}>{fixEncoding(props.winner.comment.replace(/<[^>]+>/g, ''))}</div>
                </Grid>
            </Grid>
        </div>
    </>);
}
