import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Box, Modal} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GoogleLogin from 'react-google-login';
import {form_obj, req,Storage} from "../CommonLibrary";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import youtube_loader from "../youtube_loader.gif";
import {useEffect} from "react";
export default function YoutubeComments() {
    let Store = new Storage();
    const [limitsModalOpen, setLimitsModalOpen] = React.useState(false);
    const [loadingModalOpen, setLoadingModalOpen] = React.useState(false);
    const handleLoadingModalClose = () => {
        setLoadingModalOpen(false);
    }
    let session = null;
    const initSessions = () => {
        session = Store.get("ft_session");
        if(!session){
            session = 'fts_'+new Date().getTime();
            Store.set("ft_session",session);
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = form_obj(event.target);
        let url = data.url;
        if(!isYouTubeUrl(url)){
            //toast error focus on input
            toast.error("The URL you entered is not a valid Youtube URL.");
            document.querySelector("input[name='url']").focus();
            return false;
        }
        let videoId = getYouTubeVideoId(url);
        if(!videoId){
            //toast error focus on input
            toast.error("The URL you entered doesn't include a video ID.");
            document.querySelector("input[name='url']").focus();
            return false;
        }
        let numberOfWinners = data.number_of_winners;
        if(numberOfWinners.length<1){
            toast.error("Select the number of winners.");
            document.querySelector("input[name='number_of_winners']").focus();
            return false;
        }
        if(isNaN(numberOfWinners)){
            //toast error focus on input
            toast.error("Number of winners must be a number.");
            document.querySelector("input[name='number_of_winners']").focus();
            return false;
        }
        numberOfWinners = parseInt(numberOfWinners);
        if(numberOfWinners > 3){
            toast.error("You can only pick 3 winners at a time.");
            document.querySelector("input[name='number_of_winners']").focus();
            return false;
        }
        data.session = session;
        //start loading
        setLoadingModalOpen(true);

        let response = await req("POST", "/youtube_comments", data);
        setLoadingModalOpen(false);
        if(response.pageId){
            window.location.href=`/ytw/${response.pageId}`;
        }else{
            toast.error("Something went wrong, please try again.");
        }
        console.log("response",response);
    }
    function isYouTubeUrl(url) {
        try {
            const parsedUrl = new URL(url);
            const hostname = parsedUrl.hostname;
            const pathname = parsedUrl.pathname;
            const videoId = parsedUrl.searchParams.get('v');
            if ((hostname === 'youtu.be' || hostname === 'youtube.com'|| hostname === 'www.youtube.com') &&
                (pathname === '/watch' || pathname.startsWith('/v/')) &&
                videoId) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
    function getYouTubeVideoId(url) {
        try {
            const parsedUrl = new URL(url);
            return  parsedUrl.searchParams.get('v');
        } catch (error) {
            return false;
        }
    }
    const showLimits = (event) => {
        event.preventDefault();
        //show modal
        setLimitsModalOpen(true);
    }
    const handleLimitsModalClose = () => {
        setLimitsModalOpen(false);
    }
    const responseGoogle = async (response) => {
        if(response?.tokenId){
            let auth = await req("POST", "/g_auth", {auth:response,session});
            console.log("auth",auth);
            if(auth?.jwt){
                Store.set("ft_jwt",auth.jwt);
            }

        }
        //show loading
        //send request to server
        //use local storage to keep them signed in
    }

    let mounted = false;
    React.useEffect(() => {
        if(mounted) return false;
        mounted = true;
        document.title = "Pick a Winner from YouTube Comments";
        initSessions();
    },[]);
    return (<form onSubmit={handleSubmit}>
        <div className={"container"}>
            <Grid container={true} spacing={2}>

                <Grid className={"title-area"} item={true} xs={12}>
                    <h1>Pick a Winner from YouTube Comments</h1>
                    <h3>Reward your YouTube community and Fuel your social engagement.</h3>
                    <p>Enter the youtube video url, enter the number of winners, and press the button!
                    </p>


                </Grid>
                <Grid item={true} xs={12}>
                    <TextField name={"url"} autoFocus={true} fullWidth={true} placeholder={"https://www.youtube.com/watch?v="} id="outlined-basic"
                               label="Youtube video URL" type={"url"} variant="outlined"/>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField fullWidth={true} id="nbr" label="Number of winners" defaultValue={1}
                                className={"text-center"}
                                 name={"number_of_winners"}
                               type={"number"} min={1} max={10}
                               variant="outlined"/>
                </Grid>
                <Grid item={true} xs={12}>
                    <Button fullWidth={true} className={"fullHeightButton"} type={"submit"} color="error" variant="contained">Pick
                        Winner(s)</Button>
                </Grid>
                {/*}
                <Grid item={true} className={'tool-description display-none'} xs={12}>
                <p>Sign in with Google for free and unlimited access. <a href={"#"} onClick={showLimits}>What are the limits</a>?</p>
                    <GoogleLogin
                        clientId="923065865194-g7csjf2agc79us34203sgksk9qgd0boo.apps.googleusercontent.com"
                        buttonText="Sign in with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        fullWidth={true}
                        className={"w-100 text-center"}
                        cookiePolicy={'single_host_origin'}
                        theme={'light'}
                    />
                </Grid>  */}
            </Grid>

            <div className={'bottom-links'}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} className="left-align" sm={6} xs={12}>
                        <a href='https://rewardsfuel.com/?ref=yt-cp' target="_blank">YouTube Comment winner picker from Rewards Fuel</a>
                    </Grid>
                    <Grid item={true} className="right-align" sm={6} xs={12}>
                        <a href='https://rewardsfuel.com/privacy' target="_blank">Privacy policy</a>
                    </Grid>
                </Grid>


            </div>
            <Modal
                open={limitsModalOpen}
                onClose={handleLimitsModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="limits-modal">
                    <Button className={'modal-closer'} onClick={handleLimitsModalClose}><HighlightOffIcon/></Button>
                    <h3>Limits for YouTube Comment Selection</h3>
                    <ul>
                        <li>Without Google Sign-In: Only the first 100 comments can be picked as winners.</li>
                        <li>Without Google Sign-In: You're limited to 3 winners.</li><br/><br/>
                        <li>With Google Sign-In: Unlimited comments can be picked as winners.</li>
                        <li>With Google Sign-In: Unlimited winners.</li>
                    </ul>
                    <p>This is due to YouTube's daily data request limit.</p>
                    <h4>Benefits of Signing in with Google</h4>
                    <ul>
                        <li>Signing in is free and easy</li>
                        <li>Unlimited comments can be picked as winners</li>
                    </ul>
                    <p>Sign in with Google by clicking the button.</p>
                </Box>
            </Modal>
            <Modal
                open={loadingModalOpen}
                onClose={handleLoadingModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className="loading-modal">
                    <h3>Gathering comments...</h3>
                    <img src={youtube_loader} alt="loading"/>
                </Box>
            </Modal>

        </div>
        <ToastContainer />
    </form>);
}
