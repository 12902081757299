import Grid from "@mui/material/Grid";
import { useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {req,Storage} from "../CommonLibrary";
import YoutubeWinner from "./YouTubeCommentsComponents/YoutubeWinner";
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    LineShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import * as React from "react";
import Button from "@mui/material/Button";
import {Alert, Box, IconButton, Modal, Typography} from "@mui/material";

export default function YouTubeWinners() {
    const params = useParams();
    let Store = new Storage();
    let formattedNumberOfComments =0;
    const [winningData, setWinningData] = useState(false);
    const [winners, setWinners] = useState([]);
    const [open, setOpen] = React.useState(true);
    const [commentModalOpen, setCommentModalOpen] = useState(false);
    let contestHolderMessage = '';

    const toggleCommentCountModal = () => {
        setCommentModalOpen(true);
    }
    const closeModal = () => {
        setCommentModalOpen(false);
    }
    const getWinningData = async () => {
        let id = params?.id;
        if (!id) {
            return;
        }
        let response = await req("GET", "/yt_winner?id=" + id);
        setWinningData(response.winningData);
        let i = 0;
        let tempArray = [];
        for (const winner of response.winningData.winners) {
            tempArray.push(<YoutubeWinner key={i} winner={winner}/>);
            i++;
        }
        setWinners(tempArray);
    }
    let mounted = false;

    useEffect(() => {
        if (mounted) return;
        document.title = "Youtube comment winners";
        getWinningData();
    }, []);
    if (!winningData || !params?.id) {
        return ("Loading...");
    }
    let shareLocation = window.location.href;

    let plural = "";
    if(winningData.winners.length>1)
        plural = "s";
    if(winningData.session = Store.get("ft_session")){
        contestHolderMessage =  <Collapse in={open}><Alert className={'ch-message'}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setOpen(false);
                        Store.set("ft_session",null);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            severity="success" ><div className={'text-center w-100'}>Your winner{plural} have been selected.
            &nbsp;&nbsp; <a href={"/youtube/"}>To pick new winners, click here.</a><br/><b>We haven't</b> contacted the winners. Contact winner by clicking their name.</div></Alert></Collapse>;
    }
    let shareMessage = `Check out who won our #contest! Winner${plural} randomly from YouTube comments.`;
    const pickedTime = new Date(parseInt(winningData.pickedAt)).toString();
    formattedNumberOfComments =winningData.nbrOfComments.toLocaleString();
    return (
        <div className={'container yt-comment-winners-container'}>
            <Grid container={true} spacing={2}>
                <Grid item>
                    <h1>Winner{plural}</h1>
                    <p>Comment{plural} randomly selected from
                         <Button className={"how-comments-are-counted"} onClick={toggleCommentCountModal}>{formattedNumberOfComments}</Button>
                        comments at {pickedTime}</p>
                {winners}
                <div className={"share-button-container"}>
                    <FacebookShareButton url={shareLocation} quote={shareMessage}>
                        <FacebookIcon size={32} round={true}/>
                    </FacebookShareButton>
                    <TwitterShareButton url={shareLocation} title={shareMessage}>
                        <TwitterIcon size={32} round={true}/>
                    </TwitterShareButton>
                    <WhatsappShareButton url={shareLocation} title={shareMessage}>
                        <WhatsappIcon size={32} round={true}/>
                    </WhatsappShareButton>
                    <TelegramShareButton url={shareLocation} title={shareMessage}>
                        <TelegramIcon size={32} round={true}/>
                    </TelegramShareButton>
                    <LinkedinShareButton url={shareLocation} title={shareMessage}>
                        <LinkedinIcon size={32} round={true}/>
                    </LinkedinShareButton>
                    <RedditShareButton url={shareLocation} title={shareMessage}>
                        <RedditIcon size={32} round={true}/>
                    </RedditShareButton>
                    <EmailShareButton url={shareLocation} subject={shareMessage}>
                        <EmailIcon size={32} round={true}/>
                    </EmailShareButton>
                    <LineShareButton url={shareLocation} title={shareMessage}>
                        <LineIcon size={32} round={true}/>
                    </LineShareButton>
                </div>
                </Grid>
            </Grid>
            {contestHolderMessage}
            <div className={'bottom-links'}>
                <a href='https://rewardsfuel.com/?ref=yt-cp' target="_blank">Win big with Rewards Fuel's Random Winner Generator - winners from YouTube comments made easy!</a>
                <a href='https://rewardsfuel.com/privacy' target="_blank">Privacy policy</a>
            </div>
            <Modal
                open={commentModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={"comment-count-modal"}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        How comments are counted
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>When a contest holder selects the 'pick winner(s)' button, we collect all comments.</p>
                        {/*<p>If the contest holder hasn't signed in with Google, we have a limit of 100 comments due to Google's daily query restrictions.</p>*/}
                        <p>Note that the number of comments displayed on YouTube may differ from ours as we only gather the main comments, not replies, and the comment count can change over time.</p>
                    </Typography>
                    <Button className={"w-100 mt-2"} onClick={closeModal}>Close</Button>
                </Box>
            </Modal>
        </div>
    );
}
