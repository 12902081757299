import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import YoutubeComments from "./tools/YoutubeComments";
import Privacy from "./Privacy";
import YouTubeWinners from "./tools/YouTubeWinners";
import {useEffect} from "react";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
    useEffect(() => {
        document.title = "Rewards Fuel Free Tools";
        const script = document.createElement('script');
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.3/iframeResizer.contentWindow.js";
        script.integrity = "sha512-zbY3jHnwgjUhMNyUStmDPaq4ZZIvHCjNHNBekbg28aTIBKVffChq8Z7kp+evgYLOrdsrbfRRmx3FhN6AD3f+tQ==";
        script.crossOrigin = "anonymous";
        script.referrerPolicy = "no-referrer";
        document.head.appendChild(script);

    }, [])

    let lightTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    let darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    let theme = lightTheme;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = darkTheme;
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className="rf-free-tools-body">
                <BrowserRouter>
                    <Routes>
                        <Route path="/youtube/" element={<YoutubeComments/>}/>
                        <Route path="/ytw/:id" element={<YouTubeWinners/>}/>
                        <Route path="/privacy/*" element={<Privacy/>}/>
                        <Route path="/*" element={<YoutubeComments/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
